import './Headline.css';

const Headline = ({ style, children, classes, ...rest }) => {
  return (
    <div
      className={['Headline', classes && classes].flat().join(' ')}
      style={{
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Headline;
