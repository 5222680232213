/* eslint import/prefer-default-export: 0 */
export const Colors = {
  // Neutral
  transparent: '#00000000',
  white: '#FFFFFF',
  black: '#000000',
  black75: '#404040',
  black60: '#666666',
  black40: '#999999',
  black15: '#D9D9D9',
  gray100: '#F9FAFA',
  gray200: '#F4F5F6',
  gray300: '#ECEEEF',
  gray400: '#CED2D4',
  gray500: '#A1ABAF',

  // Brand
  primaryGreen: '#4B4D00',
  primaryYellow: '#E5EA00',
  beige: '#FFFBF4',
  richBeige: '#F0E8D9',
  camel: '#D9BE8F',
  darkBrown: '#252015',
  lightBrown: '#c5bba4',

  // Status
  success: '#7ACE0E',
  warning: '#E5EA00',
  danger: '#DE3B00',
};
