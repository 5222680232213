import Text from './UI/Text';
import DivWithBackground from './UI/DivWithBackground';
import './CollaborationPartner.css';
import { openUrlInNewWindow } from '../helpers/utils';

const CollaborationPartner = ({ partnerLogo, link }) => {
  return (
    <div className={'CollaborationPartner'}>
      <div
        className={'collaboration-content'}
        onClick={() => (link ? openUrlInNewWindow(link) : null)}
      >
        <Text classes={'collaboration-text'}>{`in collaboration with`}</Text>
        <DivWithBackground
          backgroundImage={partnerLogo}
          contain
          style={{ width: '120px', height: '50px' }}
        />
      </div>
    </div>
  );
};
export default CollaborationPartner;
