import {
  getCurrentUser,
  fetchUserAttributes,
  signIn,
  signOut,
  confirmSignIn,
} from 'aws-amplify/auth';

export const getUserAuth = async () => {
  try {
    const { username, userId } = await getCurrentUser();
    const attributes = await fetchUserAttributes();
    return { username, userId, attributes };
  } catch (e) {
    console.log('getUser error', e);
    throw e;
  }
};

export const signInUser = async (username) => {
  try {
    const { nextStep } = await signIn({
      username,
      options: {
        authFlowType: 'CUSTOM_WITHOUT_SRP',
      },
    });
    return nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE';
  } catch (e) {
    console.log('signInUser error', e);
    throw e;
  }
};

export const confirmSignInUser = async (code) => {
  try {
    const { isSignedIn } = await confirmSignIn({ challengeResponse: code });
    return isSignedIn;
  } catch (e) {
    console.log('confirmSignInUser error', e);
    throw e;
  }
};

export const signOutUser = async () => {
  try {
    await signOut({ global: true });
  } catch (e) {
    console.log('signOutUser error', e);
    throw e;
  }
};
