import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUser } from '../store/redux/user';
import { setAuthLink } from '../store/redux/enrollment';
import { PLATFORMS } from '../constants';
import { openUrlInNewWindow } from '../helpers/utils';
import { utilityAPILink } from '../api/constants';
import { invokeLambda } from '../api/lambda';
import Button from './UI/Button';
import Text from './UI/Text';
import './ExternalAuthOptions.css';

const ExternalAuthOptions = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getUser);
  const [externalRheemAuthLink, setExternalRheemAuthLink] = useState(null);

  function openExternalLinkHandler(platform) {
    openUrlInNewWindow(getExternalAuthLink(platform));
  }

  function getExternalAuthLink(platform) {
    if (platform === PLATFORMS.rheem) {
      return externalRheemAuthLink;
    }
    return utilityAPILink;
  }

  useEffect(() => {
    async function getRheemLink() {
      const url = await invokeLambda('initiateEnrollment', {
        user_id: currentUser?.userId,
      });
      setExternalRheemAuthLink(url);
      const platform = PLATFORMS.rheem;
      const payload = {};
      payload[platform] = url;
      dispatch(setAuthLink(payload));
    }
    getRheemLink();
  }, [currentUser, dispatch]);

  return (
    <div className={'ExternalAuthOptions'}>
      <Button
        onClick={() => openExternalLinkHandler(PLATFORMS.rheem)}
        disabled={!externalRheemAuthLink}
      >
        {`Login to your ${PLATFORMS.rheem} account`}
      </Button>
      <Text small classes={'disclaimer'}>{`*opens in a new window`}</Text>
      {/* <div style={{ marginLeft: '10px' }}>
            <Button
              onClick={() => openExternalLinkHandler(PLATFORMS.utility_api)}
            >
              {`Connect utility provider`}
            </Button>
          </div> */}
    </div>
  );
};

export default ExternalAuthOptions;
