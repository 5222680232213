import './Text.css';

const Text = ({ style, children, bold, small, large, classes, ...rest }) => {
  return (
    <div
      className={[
        'Text',
        bold && 'bold',
        small && 'small',
        large && 'large',
        classes && classes,
      ]
        .flat()
        .join(' ')}
      style={{
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Text;
