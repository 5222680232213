const baseURL = process.env.REACT_APP_API_ENDPOINT_BASE_URL;
const region = process.env.REACT_APP_AWS_REGION;
const onsembleStack = process.env.REACT_APP_PUBLIC_ONSEMBLE_STACK;
const utilityAPILink = `https://utilityapi.com/authorize/dev_onsemble?f=12c2f9f5-5bd0-4750-ad9b-9cf34ee64676`;
const fullStoryOrgId = process.env.REACT_APP_FULLSTORY_ORG_ID;

const METHODS = {
  GET: 'get',
  PUT: 'put',
  POST: 'post',
  PATCH: 'patch',
  DELETE: 'delete',
};

module.exports = {
  METHODS,
  baseURL,
  region,
  onsembleStack,
  utilityAPILink,
  fullStoryOrgId,
};
