import AccountLogin from '../components/AccountLogin';
import { getUserLoadingStatus, getUser } from '../store/redux/user';
import { STATUS } from '../store/redux/constants';
import { PLATFORMS } from '../constants';
import { getAuthLinkByPlatform } from '../store/redux/enrollment';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import DivWithBackground from '../components/UI/DivWithBackground';
import Text from '../components/UI/Text';
import ExternalAuthOptions from '../components/ExternalAuthOptions';
import heatpumpIdleImage from '../assets/images/heatpump-idle.png';
import rheemConnectLogo from '../assets/images/logo/rheem-connect.png';
import './Authentication.css';
import {
  getUserFullName,
  getUserPhoneNumber,
  getWelcomeMessage,
} from '../helpers/user';
import Content from '../components/UI/Content';
import CollaborationPartner from '../components/CollaborationPartner';
import { FullStory } from '@fullstory/browser';

const Authentication = () => {
  const user = useSelector(getUser);
  const userIsAuthenticated = user?.username;
  const rheemAuthLink = useSelector(getAuthLinkByPlatform(PLATFORMS.rheem));
  const loadingStatus = useSelector(getUserLoadingStatus);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (loadingStatus === STATUS.LOADING) {
      setLoading(true);
    } else {
      setTimeout(() => setLoading(false), 1000);
    }
  }, [loadingStatus]);

  useEffect(() => {
    if (user?.username) {
      FullStory('setProperties', {
        uid: user?.username,
        type: 'user',
        properties: {
          displayName: `${user?.attributes?.given_name} ${user?.attributes?.family_name}`,
          email: user?.attributes?.email,
        },
      });
    }
  }, [user, userIsAuthenticated]);

  function getHeading() {
    return userIsAuthenticated
      ? getWelcomeMessage(user?.attributes)
      : 'Unlock energy savings:';
  }

  function getSubheading() {
    return userIsAuthenticated
      ? ''
      : 'Connect your Rheem water heater to Onsemble';
  }

  function getRightContent() {
    return userIsAuthenticated ? (
      <div className={'heatpump-image'}>
        <DivWithBackground
          backgroundImage={heatpumpIdleImage}
          contain
          style={{
            width: '300px',
            height: '350px',
            backgroundPosition: 'top',
          }}
        />
      </div>
    ) : (
      <AccountLogin />
    );
  }

  function getDescription() {
    return userIsAuthenticated ? (
      <div>
        <div className={'user-info'}>
          <div className={'info-row'}>
            <Text>{`Account: `}</Text>
            <div className={'info-item'}>
              <Text>{getUserFullName(user?.attributes)}</Text>
            </div>
          </div>
          <div className={'info-row'}>
            <Text>{`Phone: `}</Text>
            <div className={'info-item'}>
              <Text>{getUserPhoneNumber(user?.attributes)}</Text>
            </div>
          </div>
        </div>
        <div className={'connect-prompt'}>
          <Text>{`Let's connect your Rheem EcoNet-enabled water heater to your Onsemble account.`}</Text>
        </div>
        <ExternalAuthOptions />
      </div>
    ) : (
      <div className={'intro-details description'}>
        <Text style={{ fontSize: '20px', lineHeight: '25px' }}>
          {
            'You can now easily link your Onsemble and Rheem accounts, allowing your home to participate in demand response events, optimize energy usage, and support a more efficient grid.'
          }
        </Text>
      </div>
    );
  }

  const bottomContent = (
    <CollaborationPartner partnerLogo={rheemConnectLogo} link={rheemAuthLink} />
  );

  return (
    <Content
      loading={loading}
      heading={getHeading()}
      subheading={getSubheading()}
      description={getDescription()}
      classes={'Authentication'}
      bottomContent={userIsAuthenticated && bottomContent}
      rightContent={getRightContent()}
    />
  );
};

export default Authentication;
