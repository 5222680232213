import { useSearchParams } from 'react-router-dom';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DivWithBackground from '../components/UI/DivWithBackground';
import Text from '../components/UI/Text';
import './ExternalAuthCallback.css';
import { getUserLoadingStatus, getUser } from '../store/redux/user';
import {
  enrollDevice,
  getEnrollmentLoadingStatus,
} from '../store/redux/enrollment';
import heatpumpConnectedImage from '../assets/images/heatpump-connected.png';
import heatpumpIdleImage from '../assets/images/heatpump-idle.png';
import rheemConnectLogo from '../assets/images/logo/rheem-connect.png';
import Headline from '../components/UI/Headline';
import { getUserFullName, getUserPhoneNumber } from '../helpers/user';
import Content from '../components/UI/Content';
import { STATUS } from '../store/redux/constants';
import CollaborationPartner from '../components/CollaborationPartner';

const ExternalAuthCallback = ({ platform }) => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const userIsAuthenticated = user?.username;
  const loadingStatus = useSelector(getUserLoadingStatus);
  const enrollmentLoadingStatus = useSelector(getEnrollmentLoadingStatus);
  const loadingText = 'Loading...';
  const errorMessage = `We've encountered an error enrolling you in the Onsemble x ${platform} program. Please contact support@onsemble.com for help.`;

  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [enrollmentDeviceInfo, setEnrollmentDeviceInfo] = useState(null);
  const [heading, setHeading] = useState(loadingText);
  const [userInfo, setUserInfo] = useState({ fullName: '', phone: '' });

  useEffect(() => {
    if (
      loadingStatus === STATUS.LOADING ||
      enrollmentLoadingStatus === STATUS.LOADING
    ) {
      setLoading(true);
    } else {
      setTimeout(() => setLoading(false), 1000);
    }
  }, [loadingStatus, enrollmentLoadingStatus]);

  useLayoutEffect(() => {
    if (user?.attributes) {
      const fullName = getUserFullName(user?.attributes);
      const phone = getUserPhoneNumber(user?.attributes);
      setUserInfo({ fullName, phone });
    }
  }, [user.attributes, userIsAuthenticated]);

  useLayoutEffect(() => {
    if (enrollmentDeviceInfo) {
      setHeading(
        <Headline>
          All set, your water heater is now{' '}
          <span className={'connected-text'}>connected!</span>
        </Headline>
      );
    }
  }, [enrollmentDeviceInfo]);

  useLayoutEffect(() => {
    if (error) {
      setHeading(`Oh no! We encountered an error`);
    }
  }, [error]);

  useEffect(() => {
    async function invokeCallbackHandler() {
      const code = searchParams?.get('code');
      const error = searchParams?.get('error');
      const state = searchParams?.get('state');
      const payload = {
        ...(code && { code }),
        ...(error && { error }),
        ...(state && { state }),
      };
      if (!Object.keys(payload).length) {
        setLoading(false);
        setHeading(`Welcome back!`);
      } else {
        await dispatch(enrollDevice(payload))
          .unwrap()
          .then((res) => {
            if (res?.errorMessage?.length) {
              setError(errorMessage);
              setEnrollmentDeviceInfo(null);
            } else {
              // we want to clear out search params so that if the user accidentally refreshes or revisits, the presence of the search params doesn't trigger a backend call
                            setSearchParams({});
              setError(null);
              setEnrollmentDeviceInfo(res);
            }
          })
          .catch((e) => {
            setError(errorMessage);
            console.log('Error with enrollmentCallback', e);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
    invokeCallbackHandler();
  }, [dispatch, errorMessage, searchParams, setSearchParams]);

  function getDescription() {
    return loading ? (
      loadingText
    ) : (
      <div>
        <div className={'user-info'}>
          {userIsAuthenticated && (
            <>
              <div className={'info-row'}>
                <Text>{`Account: `}</Text>
                <div className={'info-item'}>
                  <Text>{userInfo.fullName}</Text>
                </div>
              </div>
              <div className={'info-row'}>
                <Text>{`Phone: `}</Text>
                <div className={'info-item'}>
                  <Text>{userInfo.phone}</Text>
                </div>
              </div>
            </>
          )}
          {enrollmentDeviceInfo && (
            <div className={'info-row'}>
              <Text>{`Device(s): `}</Text>
              <div className={'info-item'}>
                {enrollmentDeviceInfo.devices.map((device, i) => (
                  <div key={i}>
                    <Text>{device}</Text>
                  </div>
                ))}
              </div>
            </div>
          )}
          {(error || enrollmentDeviceInfo) && (
            <div className={'info-row'}>
              <Text>{`Status: `}</Text>
              {error ? (
                <Text classes={'info-item'}>{error}</Text>
              ) : (
                <div className={'info-item'}>
                  <Text>connected</Text>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  const rightContent = (
    <div className={'heatpump-image'}>
      <DivWithBackground
        backgroundImage={error ? heatpumpIdleImage : heatpumpConnectedImage}
        contain
        style={{
          width: '300px',
          height: '350px',
          backgroundPosition: 'top',
        }}
      />
    </div>
  );

  const bottomContent = <CollaborationPartner partnerLogo={rheemConnectLogo} />;

  return (
    <>
      {loading ? (
        <div>
          <Text large>{loadingText}</Text>
        </div>
      ) : (
        <Content
          loading={loading}
          heading={heading}
          description={getDescription()}
          classes={'ExternalAuthCallback'}
          bottomContent={bottomContent}
          rightContent={rightContent}
        />
      )}
    </>
  );
};

export default ExternalAuthCallback;
