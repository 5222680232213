/* eslint consistent-return: 0 */
/* eslint  no-unused-expressions: 0 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { STATUS } from './constants';
import { getUserAuth, signOutUser } from '../../api/routes';

const initialState = {
  data: {},
  status: STATUS.IDLE,
};

export const signOut = createAsyncThunk('user/signOut', async (_, thunkAPI) => {
  await signOutUser();
});

export const fetchUser = createAsyncThunk(
  'user/fetchUser',
  async (_, thunkAPI) => {
    const userResult = await getUserAuth();
    if (!userResult?.username) {
      return;
    }
    return userResult;
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.data = action.payload || {};
        state.status = STATUS.IDLE;
      })
      .addCase(fetchUser.pending, (state, action) => {
        state.status = STATUS.LOADING;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = STATUS.IDLE;
      })
      .addCase(signOut.fulfilled, (state, action) => {
        state.data = {};
        state.status = STATUS.IDLE;
      });
  },
});

// Selectors to access state
export const getUser = (state) => state.user.data;
export const getUserLoadingStatus = (state) => state.user.status;

// Extract the action creators object and the reducer
const { actions, reducer } = userSlice;

export default reducer;
