/* eslint consistent-return: 0 */
/* eslint  no-unused-expressions: 0 */
import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { STATUS } from './constants';
import { invokeLambda } from '../../api/lambda';
import { PLATFORMS } from '../../constants';

const initialState = {
  status: STATUS.IDLE,
  authLinks: {},
};
initialState.authLinks[PLATFORMS.rheem] = null;

export const enrollDevice = createAsyncThunk(
  'enrollment/enrollDevice',
  async (payload, thunkAPI) => {
    const enrollmentResult = await invokeLambda('enrollmentCallback', payload);
    if (!enrollmentResult) {
      return;
    }
    return enrollmentResult;
  }
);

const enrollmentSlice = createSlice({
  name: 'enrollment',
  initialState,
  reducers: {
    setAuthLink: (state, action) => {
      state.authLinks = { ...state.authLinks, ...action.payload };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(enrollDevice.fulfilled, (state, action) => {
        state.status = STATUS.IDLE;
      })
      .addCase(enrollDevice.pending, (state, action) => {
        state.status = STATUS.LOADING;
      })
      .addCase(enrollDevice.rejected, (state, action) => {
        state.status = STATUS.IDLE;
      });
  },
});

// Selectors to access state
export const getEnrollmentLoadingStatus = (state) => state.enrollment.status;
export const getAuthLinks = (state) => state.enrollment.authLinks;
export const getAuthLinkByPlatform = (platform) =>
  createSelector(getAuthLinks, (links) => links[platform]);

// Extract the action creators object and the reducer
const { actions, reducer } = enrollmentSlice;
export const { setAuthLink } = actions;

export default reducer;
