import { Outlet, useNavigate } from 'react-router-dom';
import './Layout.css';
import logo from '../assets/images/logo/onsemble_logo_wordmark_darkbrown.png';
import backgroundImage from '../assets/images/green_waves.png';
import { getUser, signOut } from '../store/redux/user';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../components/UI/Button';
import Text from '../components/UI/Text';

const Layout = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getUser);
  const navigate = useNavigate();
  function signOutHandler() {
    dispatch(signOut());
    navigate('/');
  }

  return (
    <div className={'Layout'} style={additionalStyles}>
      <div className={'header'}>
        <img src={logo} className={'logo'} alt="logo" />
        {currentUser?.username && (
          <div className={'sign-out-container'}>
            <Button onClick={signOutHandler}>Sign out</Button>
          </div>
        )}
      </div>
      <div className={'content'}>
        <Outlet />
      </div>
      <div className={'footer'}>
        <Text small classes={'footer-text'}>
          {'© 2024 Onsemble Home, Inc. All rights reserved.'}
        </Text>
      </div>
    </div>
  );
};

export default Layout;

const additionalStyles = {
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'contain',
  backgroundPosition: '25% 75%',
  width: '100%',
  backgroundRepeat: 'no-repeat',
};
