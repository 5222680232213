import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './pages/Layout';
import Authentication from './pages/Authentication';
import ExternalAuthCallback from './pages/ExternalAuthCallback';
import {
  getAWSUserPoolId,
  getAWSClientId,
  getAWSIdentityPoolId,
} from './api/factory';
import { Amplify } from 'aws-amplify';
import { Provider } from 'react-redux';
import { store } from './store/redux/store';
import { fetchUser } from './store/redux/user';
import { region } from './api/constants';
import { PLATFORMS } from './constants';

Amplify.configure({
  Auth: {
    Cognito: {
      region,
      userPoolId: getAWSUserPoolId(),
      userPoolWebClientId: getAWSClientId(),
      userPoolClientId: getAWSClientId(),
      identityPoolId: getAWSIdentityPoolId(),
    },
  },
});

store.dispatch(fetchUser());

function App() {
  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Authentication />} />
              <Route
                path="rheem_oauth2"
                element={<ExternalAuthCallback platform={PLATFORMS.rheem} />}
              />
              {/* <Route path="*" element={<NoPage />} /> */}
            </Route>
          </Routes>
        </BrowserRouter>
      </Provider>
    </>
  );
}

export default App;
