import { formatUSPhoneNumber } from './strings';
export function getWelcomeMessage(userAttributes) {
  let message = `Hello`;
  const firstName = userAttributes?.given_name;
  if (firstName) {
    message += `, ${firstName}`;
  }
  return message;
}

export function getUserFullName(userAttributes) {
  if (!userAttributes) {
    return;
  }
  const firstName = userAttributes?.given_name;
  const lastName = userAttributes?.family_name;
  return `${firstName} ${lastName}`;
}

export function getUserPhoneNumber(userAttributes) {
  if (!userAttributes) {
    return;
  }
  return formatUSPhoneNumber(userAttributes?.phone_number);
}
