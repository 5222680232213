import { useState, createRef, useEffect } from 'react';
import './OtpInput.css';

const OtpInput = ({ onChange }) => {
  const [digits, setDigits] = useState(Array(6).fill(''));
  const inputRefs = Array(6)
    .fill(0)
    .map(() => createRef());

  useEffect(() => {
    onChange(digits.join(''));
  }, [digits, onChange]);

  const handleChange = (value, index) => {
    const newDigits = [...digits];
    newDigits[index] = value.slice(-1); // Take the last character to ensure single digit
    setDigits(newDigits);

    if (value && index < 5) {
      inputRefs[index + 1].current.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !digits[index] && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };

  return (
    <div className={'OtpInput'}>
      {digits.map((digit, index) => (
        <input
          key={index}
          ref={inputRefs[index]}
          type="number"
          maxLength="1"
          value={digit}
          onChange={(e) => handleChange(e.target.value, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          className={'digit-input'}
        />
      ))}
    </div>
  );
};

export default OtpInput;
