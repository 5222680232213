import './Input.css';

const Input = ({
  type = 'text',
  placeholder = '',
  value,
  onChange,
  style = {},
  ...props
}) => {
  // Input element with spread props for flexibility
  return (
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className={'Input'}
      style={{ ...style }}
      {...props} // Allows for additional props like 'disabled', 'readOnly', etc.
    />
  );
};
export default Input;
