import { InvokeCommand, LogType } from '@aws-sdk/client-lambda';
import { createLambdaClient } from './factory';
import { PLATFORMS } from '../constants';

// While we figure out the dev vs prod shenanigans of July 8, temp hardcoding to dev
// eventually replace back with the import from constants
const ONSEMBLE_STACK_TEMP_OVERRIDE = 'dev';
//import { onsembleStack } from './constants';
const onsembleStack = ONSEMBLE_STACK_TEMP_OVERRIDE;

const lambdaFunctions = {
  initiateEnrollment: {
    name: `vpp-${onsembleStack}-InitiateEnrollmentFunction`,
    generatePayload: (payload) => {
      return {
        platform: PLATFORMS.rheem,
        program: 'com.onsemble.onsemble',
        ...payload,
      };
    },
    parse: (res) => {
      return res.url;
    },
  },
  enrollmentCallback: {
    name: `vpp-${onsembleStack}-EnrollmentCallbackFunction`,
    generatePayload: (payload) => {
      return payload;
    },
    parse: (res) => {
      return res;
    },
  },
};

export const invokeLambda = async (funcName, payload) => {
  const client = createLambdaClient();
  const finalPayload = lambdaFunctions[funcName].generatePayload(payload);
  const command = new InvokeCommand({
    FunctionName: lambdaFunctions[funcName].name,
    Payload: JSON.stringify(finalPayload),
    LogType: LogType.Tail,
  });
  const { Payload } = await client.send(command);
  const textDecoder = new TextDecoder('utf-8');
  const response = JSON.parse(textDecoder.decode(Payload));
  return lambdaFunctions[funcName].parse(response);
};
