import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { onsembleStack, fullStoryOrgId } from './constants';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { region } from './constants';
import { LambdaClient } from '@aws-sdk/client-lambda';
import { init as initFullStory } from '@fullstory/browser';

export const isStackProd = () => {
  return onsembleStack === 'prod';
};

export const isStackStaging = () => {
  return onsembleStack === 'staging';
};

export const getAWSClientId = () => {
  let clientId = process.env.REACT_APP_AWS_COGNITO_CLIENT_ID;
  if (isStackStaging()) {
    clientId = process.env.REACT_APP_PUBLIC_AWS_COGNITO_CLIENT_ID_STAGING;
  } else if (isStackProd()) {
    clientId = process.env.REACT_APP_PUBLIC_AWS_COGNITO_CLIENT_ID_PROD;
  }
  return clientId;
};

export const getAWSIdentityPoolId = () => {
  let identityPool = process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL;

  if (isStackStaging()) {
    identityPool =
      process.env.REACT_APP_PUBLIC_AWS_COGNITO_IDENTITY_POOL_STAGING;
  } else if (isStackProd()) {
    identityPool = process.env.REACT_APP_PUBLIC_AWS_COGNITO_IDENTITY_POOL_PROD;
  }

  return identityPool;
};

export const getAWSUserPoolId = () => {
  let userPool = process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID;

  if (isStackStaging()) {
    userPool = process.env.REACT_APP_PUBLIC_AWS_COGNITO_USER_POOL_ID_STAGING;
  } else if (isStackProd()) {
    userPool = process.env.REACT_APP_PUBLIC_AWS_COGNITO_USER_POOL_ID_PROD;
  }

  return userPool;
};

export const getAWSUserPool = async () => {
  const poolData = {
    UserPoolId: getAWSUserPoolId(),
    ClientId: getAWSClientId(),
  };
  return new CognitoUserPool(poolData);
};

export const createLambdaClient = () => {
  const client = new LambdaClient({
    region,
    credentials: fromCognitoIdentityPool({
      identityPoolId: getAWSIdentityPoolId(),
      clientConfig: { region },
    }),
  });
  return client;
};

export const initiateFullStory = () => {
  const settings = { orgId: fullStoryOrgId };
  if (!isStackProd()) {
    settings.devMode = true;
  }
  initFullStory(settings, ({ sessionUrl }) =>
    console.log(`Started FS session: ${sessionUrl}`)
  );
};
