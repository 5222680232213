import {
  parsePhoneNumber,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from 'libphonenumber-js';
import { validUSPhoneNumber } from './patterns';

export const parseUSPhoneNumber = (phoneNumber) => {
  const parsedNumber = parsePhoneNumber(phoneNumber, 'US');
  return parsedNumber?.nationalNumber;
};

export const formatUSPhoneNumber = (phoneNumber) => {
  const parsedNumber = parsePhoneNumber(phoneNumber, 'US');
  return parsedNumber?.formatNational();
};

export const isValidUSPhoneNumber = (inputStr) => {
  // returns true for phone numbers in these formats:
  // (123) 456-7890
  // (123)456-7890
  // 123-456-7890
  // 1234567890
  // +14088889034
  const country = 'US';
  if (inputStr && inputStr.startsWith('+')) {
    return (
      isPossiblePhoneNumber(inputStr, country) &&
      isValidPhoneNumber(inputStr, country)
    );
  }
  return validUSPhoneNumber.test(inputStr);
};
