import Headline from './Headline';
import Text from './Text';
import './Content.css';

const Content = ({
  loading,
  heading,
  subheading,
  description,
  rightContent,
  bottomContent,
  classes,
}) => {
  return (
    <>
      {loading ? (
        <div>
          <Text large>Loading...</Text>
        </div>
      ) : (
        <div className={['Content', classes && classes].flat().join(' ')}>
          <div className={'main-content'}>
            <div className={'headline-container'}>
              <div className={'headline-content'}>
                <Headline classes={'headline-text'}>{heading}</Headline>
                {subheading && (
                  <Headline classes={['font-yellix-light', 'headline-text']}>
                    {subheading}
                  </Headline>
                )}
                {description && (
                  <div className={'description'}>{description}</div>
                )}
              </div>
            </div>
            {rightContent && (
              <div className={'right-container'}>{rightContent}</div>
            )}
          </div>
          {bottomContent && (
            <div className={'bottom-container'}>{bottomContent}</div>
          )}
        </div>
      )}
    </>
  );
};

export default Content;
