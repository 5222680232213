import './Button.css';

const Button = ({ onClick, children, disabled, classes, ...rest }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={['Button', classes && classes].flat().join(' ')}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
