import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { signInUser, confirmSignInUser } from '../api/routes';
import { parseUSPhoneNumber, isValidUSPhoneNumber } from '../helpers/strings';
import { fetchUser, signOut } from '../store/redux/user';
import Button from './UI/Button';
import Input from './UI/Input';
import Text from './UI/Text';
import DivWithBackground from './UI/DivWithBackground';
import OtpInput from './UI/OtpInput';
import logo from '../assets/images/logo/onsemble_logo_bug.png';
import { Colors } from '../colors';

const AccountLogin = () => {
  const dispatch = useDispatch();

  const prompts = {
    login: `Login to your Onsemble account:`,
  };

  const countryCode = 1;
  const [phone, setPhone] = useState('');
  const [loginError, setLoginError] = useState(null);
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [otp, setOTP] = useState('');

  function phoneValueHandler(value) {
    setPhone(value);
  }

  function otpValueHandler(value) {
    setOTP(value);
  }

  async function attemptLogIn(username) {
    try {
      const hasValidNextStep = await signInUser(username);
      if (hasValidNextStep) {
        setShowOTPInput(true);
        setLoginError(null);
      }
    } catch (e) {
      if (e.name === 'UserNotFoundException') {
        setLoginError('No Onsemble account associated with your phone number');
      } else {
        await signOutHandler();
        setLoginError('Error with attempting to login. Try again?');
      }
    }
  }

  async function loginHandler() {
    const formattedPhone = parseUSPhoneNumber(phone);
    const formattedUsername = `${countryCode}${formattedPhone}`;
    await attemptLogIn(formattedUsername);
  }

  async function otpHandler() {
    const isSignedIn = await confirmSignInUser(otp);
    if (isSignedIn) {
      setShowOTPInput(false);
      setLoginError(null);
      dispatch(fetchUser());
    } else {
      setLoginError('Unable to verify your code');
    }
  }

  function disableOTPNext() {
    return otp?.length !== 6;
  }

  async function signOutHandler() {
    try {
      dispatch(signOut());
      setShowOTPInput(false);
      setOTP('');
      setLoginError(null);
    } catch (e) {
      console.log('Sign out error', e);
    }
  }

  return (
    <div style={styles.accountLogin}>
      <Text
        bold
        style={{
          textAlign: 'center',
        }}
      >
        {prompts.login}
      </Text>
      <div
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <DivWithBackground
          backgroundImage={logo}
          style={{
            width: '60px',
            height: '60px',
            margin: '25px',
          }}
        />
      </div>

      <div style={styles.contentContainer}>
        {showOTPInput ? (
          <div
            style={{
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <OtpInput onChange={otpValueHandler} />
            <div style={styles.buttonContainer}>
              <Button onClick={otpHandler} disabled={disableOTPNext()}>
                {'Next'}
              </Button>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                margin: '8px auto',
              }}
            >
              <Text
                small
                style={{ color: Colors.black40 }}
              >{`Didn't get the text?`}</Text>
              <Text
                small
                style={{
                  marginLeft: '8px',
                  cursor: 'pointer',
                }}
                onClick={loginHandler}
              >
                Send again
              </Text>
            </div>
          </div>
        ) : (
          <>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div style={styles.phoneNumberInput}>
                <Input
                  onChange={(e) => phoneValueHandler(e.target.value)}
                  value={phone}
                  placeholder={`(111)-222-3333`}
                  autoComplete={'tel'}
                />
              </div>
              <div className={'explainer-text'} style={{ marginTop: '15px' }}>
                <Text
                  small
                  classes={'text-center'}
                >{`We'll text a short login code to this number`}</Text>
                <Text
                  small
                  style={{
                    color: Colors.black40,
                    textAlign: 'center',
                    marginBottom: '15px',
                  }}
                >{`Standard SMS rates may apply`}</Text>
              </div>
              <div style={styles.buttonContainer}>
                <Button
                  onClick={loginHandler}
                  disabled={!isValidUSPhoneNumber(phone)}
                >
                  {'Next'}
                </Button>
              </div>
            </div>
            {loginError && (
              <div style={styles.loginError}>
                <p>{loginError}</p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default AccountLogin;

const styles = {
  accountLogin: {
    flex: 1,
    width: '100%',
  },
  contentContainer: {
    width: '100%',
    alignItems: 'center',
    height: '100%',
  },
  buttonContainer: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    marginTop: '20px',
  },
  phoneNumberInput: {
    width: '100%',
  },
  explainerText: {
    marginTop: 16,
    marginBottom: 32,
    alignItems: 'center',
  },
  loginError: {
    marginVertical: 10,
  },
  signOutButton: {
    border: 'none',
    backgroundColor: 'transparent',
    marginLeft: '10px',
  },
};
