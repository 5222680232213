/* eslint import/prefer-default-export: 0 */
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user';
import enrollmentReducer from './enrollment';

export const store = configureStore({
  reducer: {
    user: userReducer,
    enrollment: enrollmentReducer,
  },
});
