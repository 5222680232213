import React from 'react';

const DivWithBackground = ({
  backgroundImage,
  children,
  contain,
  style = {},
  ...props
}) => {
  // Inline style for background image
  const backgroundStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: contain ? 'contain' : 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    ...style,
  };

  return (
    <div style={backgroundStyle} {...props}>
      {children}
    </div>
  );
};

export default DivWithBackground;
